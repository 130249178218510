import { useEffect, useMemo } from "react";
import type { AppProps } from "next/app";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { WagmiConfig, configureChains, createConfig, mainnet } from "wagmi";
import { Chain, goerli, polygon, polygonMumbai } from "viem/chains";
import { SolanaWalletProvider } from "@dustlabs/profiles/solana";
import { jsonRpcProvider } from "@wagmi/core/providers/jsonRpc";
import { ChainIcon, DeIdConfig } from "@shared/components";
import {
  RainbowKitProvider,
  connectorsForWallets,
  darkTheme,
} from "@rainbow-me/rainbowkit";
import {
  injectedWallet,
  phantomWallet,
  metaMaskWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";
import "@rainbow-me/rainbowkit/styles.css";
import "../styles/globals.css";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
});

const App = ({ Component, pageProps }: AppProps) => {
  const getChains = () => {
    switch (process.env.NEXT_PUBLIC_EVM_CHAIN) {
      case "mainnet":
        return [mainnet];
      case "matic":
        return [polygon];
      case "goerli":
        return [goerli];
      case "mumbai":
        return [polygonMumbai];
      default:
        return [mainnet, polygon];
    }
  };

  const chains = getChains() as Chain[];

  const { publicClient } = configureChains(chains, [
    jsonRpcProvider({
      rpc: (chain: Chain) => ({
        http: `${process.env.NEXT_PUBLIC_EVM_RPC}`,
      }),
    }),
  ]);

  const connectors = connectorsForWallets([
    {
      groupName: "Suggested",
      wallets: [
        injectedWallet({ chains }),
        phantomWallet({ chains }),
        metaMaskWallet({
          projectId: `${process.env.NEXT_PUBLIC_WALLETCONNECT_ID}`,
          chains,
        }),
        walletConnectWallet({
          version: "2",
          projectId: `${process.env.NEXT_PUBLIC_WALLETCONNECT_ID}`,
          chains,
        }),
      ],
    },
  ]);

  const config = createConfig({
    autoConnect: true,
    connectors,
    publicClient,
  });

  useEffect(() => {
    console.log(`
                                    ▐██████████████
                              ▄▄▄▄▄▄███████████████▄▄▄▄▄▄
                              ███████████████████████████
                          ▐██████████████     ▐█████████████▌
                          ▐██████████████     ▐█████████████▌
                          ▐██████████▌           ▐██████████▌
                       ▐██████████                   ███████████
                       ▐██████████▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄███████████
                       ▐████████████████████████████████████████
                          ▐████  ████████     ████████  ████▌
                       ▄▄▄█████  ████████     ████████  █████▄▄▄
                       ████████                         ████████
                           ███████      ███████      ███████▌
                           ███████   ▄▄▄███████▄▄▄   ███████▌
                              ████  ▐████     ▐███▌  ████
                              ███████████████████████████
                              ███████████████████████████
                              ███████████     ▐██████████
                                 █████████████████████
                                 █████████████████████
                                    ▐██████████████

                                    
                                 By Degens, For Degens
    `);
  }, []);

  return (
    <WagmiConfig config={config}>
      <RainbowKitProvider
        chains={chains}
        avatar={() => (
          <div className="rounded-full bg-lightCard p-[30%]">
            <ChainIcon />
          </div>
        )}
        theme={darkTheme({
          borderRadius:
            process.env.NEXT_PUBLIC_PROJECT_ID === "y00ts" ? "none" : "medium",
          fontStack: "system",
        })}
      >
        <SolanaWalletProvider rpc={`${process.env.NEXT_PUBLIC_RPC}`}>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <DeIdConfig>
              <Component {...pageProps} />
            </DeIdConfig>
          </QueryClientProvider>
        </SolanaWalletProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  );
};

export default App;

import dynamic from "next/dynamic";

// @icons
const Clock = dynamic(() => import("./@icons/Clock"));
const ClubsIcon = dynamic(() => import("./@icons/ClubsIcon"), { ssr: false });
const DropIcon = dynamic(() => import("./@icons/DropIcon"), { ssr: false });
const ExplorerIcon = dynamic(() => import("./@icons/ExplorerIcon"), {
  ssr: false,
});
const MintIcon = dynamic(() => import("./@icons/MintIcon"), { ssr: false });
const MintT00bsIcon = dynamic(() => import("./@icons/MintT00bsIcon"), {
  ssr: false,
});
const RewardsIcon = dynamic(() => import("./@icons/RewardsIcon"), {
  ssr: false,
});
const StakingIcon = dynamic(() => import("./@icons/StakingIcon"), {
  ssr: false,
});
const InstagramIcon = dynamic(() => import("./@icons/InstagramIcon"), {
  ssr: false,
});
const TwitterIcon = dynamic(() => import("./@icons/TwitterIcon"), {
  ssr: false,
});
const DiscordIcon = dynamic(() => import("./@icons/DiscordIcon"), {
  ssr: false,
});
const DustLabsIcon = dynamic(() => import("./@icons/DustLabsIcon"), {
  ssr: false,
});
const DustLabsIcon2 = dynamic(() => import("./@icons/DustLabsIcon2"), {
  ssr: false,
});
const InfoIcon = dynamic(() => import("./@icons/InfoIcon"), { ssr: false });
const SolanaIcon = dynamic(() => import("./@icons/SolanaIcon"), { ssr: false });
const UpdatesIcon = dynamic(() => import("./@icons/UpdatesIcon"), {
  ssr: false,
});
const LogoIcon = dynamic(() => import("./@icons/LogoIcon"), { ssr: false });
const MoreIcon = dynamic(() => import("./@icons/MoreIcon"), { ssr: false });
const CloseIcon = dynamic(() => import("./@icons/CloseIcon"), { ssr: false });
const MagicEdenIcon = dynamic(() => import("./@icons/MagicEdenIcon"), {
  ssr: false,
});
const HyperspaceIcon = dynamic(() => import("./@icons/HyperspaceIcon"), {
  ssr: false,
});
const OrdinalHubIcon = dynamic(() => import("./@icons/OrdinalHubIcon"), {
  ssr: false,
});

// atoms
const ArrowToggle = dynamic(() => import("./atoms/ArrowToggle"));
const Backdrop = dynamic(() => import("./atoms/Backdrop"));
const Button = dynamic(() => import("./atoms/Button"));
const BlurButton = dynamic(() => import("./atoms/BlurButton"));
const ChainIcon = dynamic(() => import("./atoms/ChainIcon"));
const Checkbox = dynamic(() => import("./atoms/Checkbox"));
const ToggleSwitch = dynamic(() => import("./atoms/ToggleSwitch"));
const ColorPalette = dynamic(() => import("./atoms/ColorPalette"));
// const MultiChainConnectButton = dynamic(() => import("./atoms/MultiChainConnectButton"));
const ConnectButton = dynamic(() => import("./atoms/ConnectButton"));
const NetworkSelector = dynamic(() => import("./atoms/NetworkSelector"));
const Dropdown = dynamic(() => import("./atoms/Dropdown"));
const FullResolutionDownload = dynamic(
  () => import("./atoms/FullResolutionDownload")
);
export * from "./atoms/Ping";
const Spin = dynamic(() => import("./atoms/Spin"));
const ColorBlock = dynamic(() => import("./atoms/ColorBlock"));
const LoadingState = dynamic(() => import("./atoms/LoadingState"));
const LoadingDots = dynamic(() => import("./atoms/LoadingDots"));
export * from "./atoms/Select";
const Typography = dynamic(() => import("./atoms/Typography"));
const OpenseaButton = dynamic(() => import("./atoms/OpenseaButton"));
const PageDivider = dynamic(() => import("./atoms/PageDivider"));
const NavItem = dynamic(() => import("./atoms/NavItem"));
const DashedCircle = dynamic(() => import("./atoms/DashedCircle"));
export * from "./atoms/Toggle";
const BorderLine = dynamic(() => import("./atoms/BorderLine"));
const Tooltip = dynamic(() => import("./atoms/Tooltip"));
const RarityTag = dynamic(() => import("./atoms/RarityTag"));

// molecules
const AttributeDetails = dynamic(() => import("./molecules/AttributeDetails"));
const Attribute = dynamic(() => import("./molecules/Attribute"));
const Card = dynamic(() => import("./molecules/Card"));
const ColorBlockLogo = dynamic(() => import("./molecules/ColorBlockLogo"));
const ImageUpload = dynamic(() => import("./molecules/ImageUpload"));
const Footer = dynamic(() => import("./molecules/Footer"));
const MobileMenu = dynamic(() => import("./molecules/MobileMenu"));
const ModalContent = dynamic(() => import("./molecules/ModalContent"));
const SimpleFooter = dynamic(() => import("./molecules/SimpleFooter"));
const NavItems = dynamic(() => import("./molecules/NavItems"));
const ImageShimmer = dynamic(() => import("./molecules/ImageShimmer"));
const DustLabsFooter = dynamic(() => import("./molecules/DustLabsFooter"));
const MoreBox = dynamic(() => import("./molecules/ModalContent"));
const MoreMenuMobile = dynamic(() => import("./molecules/MoreMenuMobile"));
const MoreMenuWeb = dynamic(() => import("./molecules/MoreMenuWeb"));
const CircularSteppedProgress = dynamic(
  () => import("./molecules/CircularSteppedProgress")
);
const ToggleButtonGroup = dynamic(
  () => import("./molecules/ToggleButtonGroup")
);

// organisms
const AppBarWeb = dynamic(() => import("./organisms/AppBarWeb"));
const AttributeList = dynamic(() => import("./organisms/AttributeList"));
const DocumentHead = dynamic(() => import("./organisms/DocumentHead"));
const Modal = dynamic(() => import("./organisms/Modal"));
const AppBarMobile = dynamic(() => import("./organisms/AppBarMobile"));
const Drawer = dynamic(() => import("./organisms/Drawer"));

// providers
export { default as DeIdConfig } from "./providers/DeIdConfig";

// templates
const PageTemplate = dynamic(() => import("./templates/PageTemplate"));
export { default as ClientOnly } from "./templates/ClientOnly";

export {
  ArrowToggle,
  ChainIcon,
  MagicEdenIcon,
  HyperspaceIcon,
  OrdinalHubIcon,
  AttributeList,
  ColorPalette,
  Attribute,
  AppBarWeb,
  AppBarMobile,
  AttributeDetails,
  Backdrop,
  Button,
  BlurButton,
  Card,
  Checkbox,
  ClubsIcon,
  ToggleSwitch,
  ConnectButton,
  NetworkSelector,
  DocumentHead,
  Drawer,
  Dropdown,
  DropIcon,
  FullResolutionDownload,
  ImageUpload,
  ColorBlockLogo,
  Footer,
  LoadingState,
  LoadingDots,
  ToggleButtonGroup,
  MobileMenu,
  MintIcon,
  MintT00bsIcon,
  Modal,
  ModalContent,
  OpenseaButton,
  PageTemplate,
  ExplorerIcon,
  RewardsIcon,
  SimpleFooter,
  Spin,
  StakingIcon,
  NavItems,
  NavItem,
  BorderLine,
  ColorBlock,
  ImageShimmer,
  Tooltip,
  RarityTag,
  Typography,
  PageDivider,
  InstagramIcon,
  TwitterIcon,
  DiscordIcon,
  DustLabsIcon,
  DustLabsIcon2,
  InfoIcon,
  SolanaIcon,
  UpdatesIcon,
  LogoIcon,
  MoreIcon,
  CloseIcon,
  DustLabsFooter,
  MoreBox,
  MoreMenuMobile,
  MoreMenuWeb,
  CircularSteppedProgress,
  DashedCircle,
  Clock,
};

// TODO: Migrate all icons in this file to use @dustlabs/web/icons
export * from "./@icons/EventsIcon";

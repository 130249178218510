import React, { FC, ReactNode } from "react";
import { DeIdProvider } from "@dustlabs/profiles/core";
import { DeIdEvm } from "@dustlabs/profiles/evm";
import { AddBtcWalletButton, BtcWalletProvider } from "@dustlabs/profiles/btc";
import { AddSolanaWalletButton } from "@dustlabs/profiles/solana";
import {
  ProfileWalletNetwork,
  ProjectMetadata,
} from "@dustlabs/profiles/types";
import { GLOBAL_CONFIG } from "@shared/constants";
require("@solana/wallet-adapter-react-ui/styles.css");

interface Props {
  children: ReactNode;
}

const DeIdConfig: FC<Props> = ({ children }: Props) => {
  const PROJECTS: ProjectMetadata[] = [
    {
      id: "degods",
      label: "DeGods",
      contract: `${process.env.NEXT_PUBLIC_NFT_CONTRACT}`,
    },
    {
      id: "btc-degods",
      label: "BTC DeGods",
      contract: "BTC DeGods",
    },
  ];

  return (
    <DeIdEvm api={`${process.env.NEXT_PUBLIC_PROFILES_API}`}>
      {(providerProps) => (
        <DeIdProvider
          {...providerProps}
          projects={PROJECTS}
          deIdModalProviderProps={{
            options: {
              socialDescription: {
                TWITTER: `Get followed by ${GLOBAL_CONFIG.TWITTER_HANDLE} for linking your twitter`,
                DISCORD: "Get access to the DeXYZ server and holder channels",
              },
              successEffect: (
                <lottie-player
                  autoplay
                  mode="normal"
                  src="https://assets8.lottiefiles.com/packages/lf20_p4Lr7Z5IWy.json"
                  style={{ width: "100vw", height: "100vh" }}
                ></lottie-player>
              ),
              injectedNetworks: [
                {
                  network: ProfileWalletNetwork.BITCOIN,
                  addWalletButton: (deId, options) => (
                    <BtcWalletProvider>
                      <AddBtcWalletButton deId={deId} options={options} />
                    </BtcWalletProvider>
                  ),
                },
                {
                  network: ProfileWalletNetwork.SOLANA,
                  addWalletButton: (deId, options) => (
                    <AddSolanaWalletButton deId={deId} options={options} />
                  ),
                },
              ],
            },
          }}
        >
          {children}
        </DeIdProvider>
      )}
    </DeIdEvm>
  );
};

export default DeIdConfig;

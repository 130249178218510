import axios from "axios";
import { useAccount, usePublicClient } from "wagmi";
import {
  useQueries,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";
import { getStakedNFTs, getWalletNFTs } from "@shared/evm";
import { AttributeMetadata, NftMetadata } from "@shared/types";

/**
 * Hook that fetches owned NFT tokenIds.
 *
 * @returns loading states and tokenIds.
 */
export const useOwnedNfts = () => {
  const { address } = useAccount();
  const publicClient = usePublicClient();

  const fetchOwnedNfts = async () => {
    if (address && publicClient) {
      try {
        const newStakedList: number[] = await getStakedNFTs(
          address,
          publicClient
        );

        const newNftIds: Array<number> = await getWalletNFTs(address);

        const allNfts = newNftIds.concat(newStakedList);

        return Array.from(new Set(allNfts));
      } catch (error) {
        console.error(error);
        return [];
      }
    }
    return [];
  };

  return useQuery<number[]>(["ownedNfts", address], fetchOwnedNfts, {
    enabled: Boolean(address) && Boolean(publicClient),
  });
};

/**
 * Hook that fetches owned NFT metadata.
 *
 * @returns loading states and NFT metadata.
 */
export const useNftsMetadata = () => {
  const { data: ownedNfts } = useOwnedNfts();

  const fetchNftMetadata = async (metadataURI: string) => {
    const response = await axios.get(metadataURI);
    const metadata = response.data;
    metadata["id"] = Number(metadata.name.split("#")[1] - 1);
    return metadata;
  };

  const queryArray: UseQueryOptions<NftMetadata, Error>[] =
    ownedNfts?.map((tokenId: number) => ({
      queryKey: ["getTokenMetadata", tokenId],
      queryFn: () =>
        fetchNftMetadata(
          process.env.NEXT_PUBLIC_METADATA_URL + tokenId.toString() + ".json"
        ),
    })) ?? [];

  return useQueries(queryArray);
};

/**
 * Hook that refetch all cached NFT data.
 *
 * @returns refetch function
 */
export const useRefetchWalletNftData = () => {
  const nftsMetadata = useNftsMetadata();
  const { refetch } = useOwnedNfts();

  const refetchAll = async () => {
    await refetch();
    nftsMetadata.map((query) => query.refetch());
  };

  return refetchAll;
};

/**
 * Hook that fetches unique traits from owner's NFTs.
 *
 * @returns loading states and data.
 */
export const useOwnedTraits = () => {
  const nftsMetadata = useNftsMetadata();

  const traits = nftsMetadata
    .flatMap(
      ({ data: nftMetadata }: UseQueryResult<NftMetadata, Error>) =>
        nftMetadata?.attributes
    )
    .filter(
      (
        attribute: AttributeMetadata | undefined
      ): attribute is AttributeMetadata => !!attribute
    );

  return {
    traits,
    isLoading: nftsMetadata.some((query) => query.isLoading),
    isFetched: nftsMetadata.every(
      (query) => query.isFetched && query.data !== undefined
    ),
  };
};

import { createContext, MutableRefObject } from "react";

export interface AudioContext {
  audio: MutableRefObject<HTMLAudioElement | null> | null;
  playing: boolean;
  toggleOn: () => void;
  togglePause: () => void;
  toggleOff: () => void;
  fadeTo: (newVolume: number) => void;
}

export const AudioContext = createContext<AudioContext>({
  audio: null,
  playing: false,
  toggleOn: () => {},
  togglePause: () => {},
  toggleOff: () => {},
  fadeTo: (newVolume: number) => {},
});

import React, { FC, HTMLAttributes } from "react";

export interface ToggleProps extends HTMLAttributes<HTMLDivElement> {
  selected: boolean;
  disabled?: boolean;
}

export const Toggle: FC<ToggleProps> = ({
  selected,
  children,
  className,
  disabled = false,
  ...componentProps
}: ToggleProps) => (
  <div
    {...componentProps}
    className={`w-[57px] h-[33px] flex justify-center items-center rounded-md ${
      selected ? "bg-darkSurface text-darkFontPrimary" : ""
    } ${
      disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
    } ${className}`}
  >
    {children}
  </div>
);

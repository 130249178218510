import {
  ClubsIcon,
  DeGodsLogoProps,
  DeGodsLogo,
  DropIcon,
  EventsIcon,
  ExplorerIcon,
  MagicEdenIcon,
  MintIcon,
  MintT00bsIcon,
  MoreIcon,
  OpenseaIcon,
  RewardsIcon,
  SignpostIcon,
  StakingIcon,
  Y00tsLogo,
  Y00tsLogoProps,
} from "@dustlabs/web/icons";
import { GlobalConfig } from "@shared/types";

export const ENV: string =
  process.env.NEXT_PUBLIC_NETWORK === "mainnet-beta" ? "prod" : "dev"; // Change for RPC and whitelist values
export const DEFAULT_PROJECT_ID = "degods"; // Default project id if NEXT_PUBLIC_PROJECT_ID missing

const allConfigs: GlobalConfig = [
  {
    ID: "y00ts",
    ORG_NAME: "y00ts",
    NFT_NAME: "y00t",
    SITE_NAME: "y00ts",
    SITE_SLOGAN: "we're building y00topia, together.",
    BASE_URL: "https://www.y00ts.com",
    PARENT_SITE_URL: "https://delabs.xyz",
    SEASON: 2,
    // API_URL: "https://os-y00ts-api-83jx.zeet-dust-labs.zeet.app", // Staging
    API_URL: "https://api.y00ts.com",
    // API_URL: "http://localhost:8001", // DEV
    STATIC_URL: "https://static.y00ts.com",
    ATTRIBUTES_STATIC_PATH: "/explorer/attributes/",
    DEFAULT_REDIRECT: "/",
    MINT_CAP_PER_TRANSACTION: 10,
    STAKE_POOL_IDENTIFIER: ENV === "dev" ? "y00ts-test1" : "y00ts",
    STAKING_PAYMENT_IDENTIFIER: "stake-y00ts",
    UNSTAKING_PAYMENT_IDENTIFIER: "unstake-y00ts",
    DUST_TOKEN_ADDRESS: "DUSTawucrTsGU8hcqRdHDCbuYhCPADMLM2VcCb8VnFnQ",
    HYPERSPACE_PROJECT_ID: "A4FM6h8T5Fmh9z2g3fKUrKfZn6BNFEgByR8QGpdbQhk1",
    ASSETS: "/y00ts",
    DISCORD: "https://discord.gg/dexyz",
    TWITTER: "https://twitter.com/y00tsNFT",
    TWITTER_HANDLE: "@y00tsNFT",
    INSTAGRAM: "https://instagram.com/they00ts",
    OPENSEA: "https://opensea.io/collection/y00ts",
    MAGIC_EDEN: "https://magiceden.io/collections/polygon/y00ts",
    FAVICON: "/favicon.png",
    APP_LOGO: {
      image: (props: Y00tsLogoProps) => <Y00tsLogo {...props} />,
      height: 44,
      width: 32,
    },
    LOADING_STATE_DARK: "/y00ts-00.svg",
    LOADING_STATE_LIGHT: "/y00ts-00.svg",
    TOTAL_NFTS: 15000,
    TOTAL_NFTS_ALL_CHAINS: 15000,
    REWARD_ICON: "/y00t-point.svg",
    REWARD_ICON_GREY: "/y00t-point-grey.svg",
    REWARD_NAME: "y00tPoint",
    NFT_VERSION_TOGGLE: false,
    FOOTER_LOGO_DARK: {
      image: "/y00ts-logo.svg",
      height: 42,
      width: 100,
    },
    FOOTER_LOGO_LIGHT: {
      image: "/y00ts-logo.svg",
      height: 42,
      width: 100,
    },
    COLOR_BLOCKS: [
      "#3C68FF",
      "#FFCAE0",
      "#FFD438",
      "#FF8B4A",
      "#FF3535",
      "#27996B",
    ],
    OPACITY_ANIMATION: {
      initial: { opacity: 0 },
      animate: { opacity: 1 },
      exit: { opacity: 0 },
      transition: { duration: 0.5 },
    },
    COLOR_ANIMATION: {
      initial: { opacity: 0, color: "black" },
      animate: {
        opacity: 1,
        color: "black",
      },
      transition: { duration: 0.5 },
    },
    CARD_ANIMATION: {
      whileHover: { scale: 1.05 },
      transition: { duration: 0.2 },
    },
    CONNECT_VIEW: {
      image: "/y00ts-logo.svg",
      height: 50.75,
      width: 122,
    },
    HEAD: {
      title: "y00ts",
      description: "Welcome to y00topia",
      image: "https://y00ts.com/y00ts/landing.png",
    },
    APPS: [
      // {
      //   key: "drops",
      //   path: "/drops",
      //   label: "Drops",
      //   enabled: true,
      //   image: (selected: boolean) => <DropIcon selected={selected} />,
      //   meta: {
      //     title: "y00t Drops",
      //     description: `revealing 1 y00t every 3 minutes`,
      //     image: "https://y00ts.com/y00ts/drops.png",
      //   },
      //   powHeader: "Proof of Work",
      //   powSubHeader: "Every block is a day of work on DeGods.",
      //   contributionsHeader: "Proof of Work",
      //   startYear: 2021,
      //   endYear: 2022,
      //   levelsCount: 4,
      //   levelColorHexCodes: {
      //     darkMode: [
      //       "!bg-[#1E2021]",
      //       "!bg-[#38536C]",
      //       "!bg-[#54809F]",
      //       "!bg-[#6997C1]",
      //       "!bg-[#ACCBE7]",
      //     ],
      //     lightMode: [
      //       "!bg-[#EBEDF0]",
      //       "!bg-[#ACCBE7]",
      //       "!bg-[#6997C1]",
      //       "!bg-[#54809F]",
      //       "!bg-[#38536C]",
      //     ],
      //   },
      //   disableWallet: true,
      // },
      {
        key: "staking",
        path: "/staking",
        label: "Staking",
        enabled: true,
        image: (selected: boolean) => (
          <StakingIcon
            className={
              selected ? "fill-lightFontSelected" : "fill-lightFontUnselected"
            }
          />
        ),
        meta: {
          title: "y00ts Staking",
          description: "Earn points for staking your y00t",
          image: "https://y00ts.com/y00ts/staking.png",
        },
        header: "stake your y00t.",
        subheader: "start collecting rewards.",
        stakingActionsHeader: "Stake your y00ts!",
        stakingActionsSubheader:
          "Each staked y00t will earn 1 y00tPoint per minute. Spend these points at the Rewards Center.",
        showConnectInNav: true,
      },
      {
        key: "migration",
        path: "https://legacy.y00ts.com/migration",
        label: "Migration",
        enabled: true,
        image: (selected: boolean) => (
          <DropIcon
            className={
              selected ? "fill-lightFontSelected" : "fill-lightFontUnselected"
            }
          />
        ),
        externalLink: true,
      },
      {
        key: "explorer",
        path: "/explorer",
        label: "Explorer",
        enabled: true,
        image: (selected: boolean) => (
          <ExplorerIcon
            className={
              selected ? "fill-lightFontSelected" : "fill-lightFontUnselected"
            }
          />
        ),
        meta: {
          title: "y00ts Explorer",
          description: "Explore y00topia.",
          image: "https://y00ts.com/y00ts/explorer.png",
        },
        disableWallet: false,
        tabs: ["leaderboard", "overview", "attributes"],
        showConnectInNav: true,
      },
      {
        key: "clubs",
        path: "/clubs",
        label: "Clubs",
        enabled: true,
        image: (selected: boolean) => (
          <ClubsIcon
            className={
              selected ? "fill-lightFontSelected" : "fill-lightFontUnselected"
            }
          />
        ),
        meta: {
          title: "y00ts Clubs",
          description: "Create & discover sub-DAOs of y00topia",
          image: "https://y00ts.com/y00ts/clubs.png",
        },
        showConnectInNav: true,
        clubsTabs: ["clubs"],
        clubsLogo: "/clubs-logo.svg",
        clubsLogoWidth: 135,
        clubsLogoHeight: 92,
        clubsDescription:
          "A list of all the clubs in y00topia. Don't see your club here? Add a new one.",
        clubsLocationDescription:
          "A list of all cities with clubs around the globe. Find your city meet up.",
      },
      {
        key: "events",
        path: "https://lu.ma/DeLabs-Meetups",
        label: "Events",
        enabled: true,
        image: (selected: boolean) => (
          <EventsIcon className="fill-lightFontUnselected" />
        ),
        externalLink: true,
      },
      {
        key: "more",
        label: "More",
        enabled: true,
        image: (selected: boolean) => (
          <MoreIcon
            className={
              selected ? "fill-lightFontSelected" : "fill-lightFontUnselected"
            }
          />
        ),
        path: "",
        moreMenuContent: [
          {
            header: "",
            column: [
              {
                key: "mint",
                path: "https://legacy.y00ts.com/mint",
                description: "Welcome to y00topia",
                label: "Mint",
                enabled: true,
                image: <MintIcon fill="fill-lightFontSelected" />,
                externalLink: true,
              },
              {
                key: "buyY00ts",
                label: "Buy y00ts",
                description: "Purchase y00ts on OpenSea",
                path: "https://opensea.io/collection/y00ts",
                image: (
                  <OpenseaIcon width={24} height={24} outerColor="#00000" />
                ),
                enabled: true,
                externalLink: true,
              },
              {
                key: "rewards",
                path: "/rewards",
                label: "Rewards",
                description: "Earn rewards for staking y00ts",
                enabled: true,
                image: <RewardsIcon fill="fill-lightFontSelected" />,
                meta: {
                  title: "y00ts Rewards",
                  description: "Earn rewards for staking y00ts",
                  image: "https://y00ts.com/y00ts/rewards.png",
                },
                header: "Rewards Center",
                subheader:
                  "Your homebase for all the rewards you can earn and redeem with y00tPoints.",
                connectImg: "/y00ts/secret-surprise.svg",
                connectHeader: "Rewards",
                connectSubheader:
                  "The rewards center will be revealed in 30 days. To participate stake your y00ts",
                connectSubHeaderDeLay: "Coming in January 2024, or sooner.",
                stakePromptHeader: "Staked y00ts",
                stakePromptSubheader: "You dont have any staked",
                stakedTokensHeader: "Staked y00ts",
                stakedTokensSubheader:
                  "Staking your y00t gets you 1 y00tPoint for every minute staked.",
                showConnectInNav: true,
              },
            ],
          },
          {
            header: "",
            column: [
              {
                key: "mintT00bs",
                label: "Mint t00bs",
                description: "Mint a t00b by holding a DeGod",
                path: "https://t00b.y00ts.com/",
                image: <MintT00bsIcon fill="fill-lightFontSelected" />,
                enabled: true,
                externalLink: true,
              },
              {
                key: "buyT00bs",
                label: "Buy t00bs",
                description: "Purchase t00bs on Magic Eden",
                path: "https://magiceden.io/marketplace/t00bs",
                image: <MagicEdenIcon width={24} height={24} />,
                enabled: true,
                externalLink: true,
              },
              {
                key: "legacySite",
                label: "Legacy Website",
                description: "Solana version of the y00ts website",
                path: "https://legacy.y00ts.com",
                image: <SignpostIcon width={24} height={24} />,
                enabled: true,
                externalLink: true,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    ID: "degods",
    ORG_NAME: "DeGods",
    NFT_NAME: "DeGod",
    SITE_NAME: "DeGods",
    SITE_SLOGAN: "DeGods Home",
    BASE_URL: "https://degods.com",
    PARENT_SITE_URL: "https://delabs.xyz",
    SEASON: 3,
    API_URL: "https://api.degods.dustlabs.com",
    // API_URL: "http://localhost:8001", // LOCAL
    // API_URL: "https://de-api-degods-1k1x.zeet-dust-labs.zeet.app", // DEV
    STATIC_URL: "https://static.degods.com",
    ATTRIBUTES_STATIC_PATH: "/attributes/deadgods/",
    DEFAULT_REDIRECT: "/staking",
    STAKE_POOL_IDENTIFIER: ENV === "dev" ? "degods-test" : "degods",
    STAKING_PAYMENT_IDENTIFIER: "stake-degods",
    UNSTAKING_PAYMENT_IDENTIFIER: "unstake-degods",
    DUST_TOKEN_ADDRESS: "DUSTawucrTsGU8hcqRdHDCbuYhCPADMLM2VcCb8VnFnQ",
    HYPERSPACE_PROJECT_ID: "degods",
    ASSETS: "/degods",
    DISCORD: "https://discord.gg/dexyz",
    TWITTER: "https://twitter.com/DeGodsNFT",
    TWITTER_HANDLE: "@DeGodsNFT",
    INSTAGRAM: "https://www.instagram.com/thedegods/",
    OPENSEA: "https://opensea.io/collection/degods",
    MAGIC_EDEN: "https://www.magiceden.io/marketplace/degods",
    FAVICON: "/favi.png",
    LOADING_STATE_DARK: "/degods-loading-dark.svg",
    LOADING_STATE_LIGHT: "/degods-loading-light.svg",
    TOTAL_NFTS: 9465,
    TOTAL_NFTS_ALL_CHAINS: 10000,
    REWARD_ICON: "/diamond.svg",
    REWARD_ICON_GREY: "/diamond_grey.svg",
    REWARD_NAME: "DePoint",
    NFT_VERSION_TOGGLE: true,
    APP_LOGO: {
      image: (props: DeGodsLogoProps) => (
        <DeGodsLogo variant="full" {...props} />
      ),
      height: 22.3,
      width: 92,
    },
    FOOTER_LOGO_DARK: {
      image: "/degods-word-logo-dark.svg",
      height: 33,
      width: 140,
    },
    FOOTER_LOGO_LIGHT: {
      image: "/degods-word-logo-light.svg",
      height: 33,
      width: 140,
    },
    COLOR_BLOCKS: [
      "#FF3534",
      "#FF9D57",
      "#FFC34D",
      "#FFCAE0",
      "#37B875",
      "#4D91FF",
    ],
    OPACITY_ANIMATION: {
      initial: { opacity: 0 },
      animate: { opacity: 1 },
      exit: { opacity: 0 },
      transition: { duration: 0.5 },
    },
    COLOR_ANIMATION: {
      initial: { opacity: 0, color: "black" },
      animate: {
        opacity: 1,
        color: "black",
      },
      transition: { duration: 0.5 },
    },
    CARD_ANIMATION: {
      whileHover: { scale: 1.05 },
      transition: { duration: 0.2 },
    },
    CONNECT_VIEW: {
      image: "/images/connect-view.png",
      height: 222,
      width: 214,
    },
    HEAD: {
      title: "DeGods",
      description: "The #1 NFT community on the internet",
      image: `${process.env.NEXT_PUBLIC_URL}/degods/meta.png`,
    },
    GEMFARM_CONFIG: {
      farms: [
        // DEVNET DEGODS
        // "459scjLbYUgYHJu8aYkxRWfnZEmqgrNG1xWgfXdKdKqS",
        // DEVNET DEADGODS
        // "9EEnnqSi8tiH7LEJcoF6VhBnUKkmxhEz7ry5R5GEPfk5",
        // MAINNET DEGODS
        "G9nFryoG6Cn2BexRquWa2AKTwcJfumWoDNLUwWkhXcij",
        // MAINNET DEADGODS
        "8LbL9wfddTWo9vFf5CWoH979KowdV7JUfbBrnNdmPpk8",
      ],
      bankProgram: "6VJpeYFy87Wuv4KvwqD5gyFBTkohqZTqs6LgbCJ8tDBA",
      farmProgram: "FQzYycoqRjmZTgCcTTAkzceH2Ju8nzNLa5d78K3yAhVW",
      splToken: "DUSTawucrTsGU8hcqRdHDCbuYhCPADMLM2VcCb8VnFnQ",
    },
    APPS: [
      // {
      //   key: "downgrade",
      //   path: "/downgrade",
      //   label: "Downgrade",
      //   enabled: true,
      //   image: (selected: boolean) => (
      //     <DropIcon
      //       className={
      //         selected ? "fill-lightFontSelected" : "fill-lightFontUnselected"
      //       }
      //     />
      //   ),
      // },

      // {
      //   key: "migration",
      //   path: "https://legacy.degods.com/migration",
      //   label: "Migration",
      //   enabled: true,
      //   image: (selected: boolean) => (
      //     <DropIcon
      //       className={
      //         selected ? "fill-lightFontSelected" : "fill-lightFontUnselected"
      //       }
      //     />
      //   ),
      //   externalLink: true,
      // },
      // {
      //   key: "explorer",
      //   path: "/explorer",
      //   label: "Explorer",
      //   enabled: true,
      //   image: (selected: boolean) => (
      //     <ExplorerIcon
      //       className={
      //         selected ? "fill-lightFontSelected" : "fill-lightFontUnselected"
      //       }
      //     />
      //   ),
      //   meta: {
      //     title: "DeGods Rarity Explorer",
      //     description: "DeGods Rarity Explorer",
      //     image: "https://degods.com/degods/DeExplorer.png",
      //   },
      //   disableWallet: false,
      //   tabs: ["overview", "attributes", "btc_degods"],
      //   showConnectInNav: true,
      // },
      {
        key: "bridge",
        path: "https://bridge.de.xyz",
        label: "Bridge",
        enabled: true,
        image: (selected: boolean) => (
          <EventsIcon className="fill-lightFontUnselected" />
        ),
        externalLink: true,
      },
      {
        key: "explorer",
        path: "https://de.xyz/gallery?collection=DEGODS",
        label: "Gallery",
        enabled: true,
        image: (selected: boolean) => (
          <EventsIcon className="fill-lightFontUnselected" />
        ),
        externalLink: true,
      },
      // {
      //   key: "staking",
      //   path: "/staking",
      //   label: "Staking",
      //   enabled: true,
      //   image: (selected: boolean) => (
      //     <StakingIcon
      //       className={
      //         selected ? "fill-lightFontSelected" : "fill-lightFontUnselected"
      //       }
      //     />
      //   ),
      //   meta: {
      //     title: "DeGods Staking",
      //     description: "Earn points for staking your DeadGod",
      //     image: "https://degods.com/degods/staking_meta.png",
      //   },
      //   header: "DeStake",
      //   subheader: "Stake your DeGods to earn DePoints",
      //   stakingActionsHeader: "Stake your DeGods",
      //   stakingActionsSubheader:
      //     "$DUST rewards ended on Tuesday, February 21st & were distributed to all staking wallets.",
      //   showConnectInNav: true,
      // },
      // {
      //   key: "clubs",
      //   path: "/clubs",
      //   label: "Clubs",
      //   enabled: true,
      //   image: (selected: boolean) => (
      //     <ClubsIcon
      //       className={
      //         selected ? "fill-lightFontSelected" : "fill-lightFontUnselected"
      //       }
      //     />
      //   ),
      //   meta: {
      //     title: "DeGods Clubs",
      //     description: "The most diamond handed community.",
      //     image: "https://degods.com/degods/clubs_meta.png",
      //   },
      //   showConnectInNav: true,
      //   clubsTabs: ["clubs", "locations"],
      //   clubsLogo: "/clubs-logo.svg",
      //   clubsLogoWidth: 221,
      //   clubsLogoHeight: 73,
      //   clubsDescription:
      //     "A list of all the clubs. Don't see your club here? Add a new one.",
      //   clubsLocationDescription:
      //     "A list of all cities with DeClubs around the globe. Find your city meet up.",
      // },
      {
        key: "events",
        path: "https://lu.ma/DeLabs-Meetups",
        label: "Events",
        enabled: true,
        image: (selected: boolean) => (
          <EventsIcon className="fill-lightFontUnselected" />
        ),
        externalLink: true,
      },
      // {
      //   key: "holdings",
      //   path: "/holdings",
      //   label: "Holdings",
      //   enabled: false,
      //   hide: true,
      //   image: (selected: boolean) => <></>,
      //   meta: {
      //     title: "DeGods",
      //     description: "The #1 NFT community on the internet",
      //     image: `https://degods.com/degods/landing/holdings.jpg`,
      //   },
      // },
      {
        key: "brandkit",
        path: "https://brand.degods.com",
        label: "Brandkit",
        enabled: true,
        image: (selected: boolean) => <></>,
        externalLink: true,
      },
      {
        key: "more",
        label: "More",
        enabled: true,
        image: (selected: boolean) => (
          <MoreIcon
            className={
              selected ? "fill-lightFontSelected" : "fill-lightFontUnselected"
            }
          />
        ),

        path: "",
        moreMenuContent: [
          {
            header: "",
            // header: "Learn About DeGods",
            column: [
              // {
              //   key: "updates",
              //   path: "/pow",
              //   label: "Proof of Work",
              //   description: "A log of everything the team works on.",
              //   enabled: true,
              //   // image: <UpdatesIcon selected={true} />,
              //   meta: {
              //     title: "DeGods - Proof of Work",
              //     description:
              //       "A transparent daily log of everything the DeGods team works on.",
              //     image: "https://degods.com/degods/pow_meta.png",
              //   },
              //   powHeader: "Proof of Work",
              //   powSubHeader: "Every block is a day of work on DeGods.",
              //   contributionsHeader: "Proof of Work",
              //   startYear: 2021,
              //   endYear: 2022,
              //   levelsCount: 4,
              //   levelColorHexCodes: {
              //     darkMode: [
              //       "!bg-[#1E2021]",
              //       "!bg-[#38536C]",
              //       "!bg-[#54809F]",
              //       "!bg-[#6997C1]",
              //       "!bg-[#ACCBE7]",
              //     ],
              //     lightMode: [
              //       "!bg-[#EBEDF0]",
              //       "!bg-[#ACCBE7]",
              //       "!bg-[#6997C1]",
              //       "!bg-[#54809F]",
              //       "!bg-[#38536C]",
              //     ],
              //   },
              // },
              // {
              //   key: "rewards",
              //   path: "/rewards",
              //   label: "Rewards",
              //   description: "Earn rewards for staking DeadGods",
              //   enabled: true,
              //   meta: {
              //     title: "Degods Rewards",
              //     description: "Earn rewards for staking DeadGods",
              //     image: "https://degods.com/degods/rewards_meta.png",
              //   },
              //   header: "Welcome to the DeGods Rewards Center",
              //   subheader:
              //     "Your homebase for all the rewards you can earn and redeem with DePoints.",
              //   connectImg: "/degods/rewards.png",
              //   connectHeader: "Rewards",
              //   connectSubheader:
              //     "The rewards center will be revealed in 30 days. To participate start staking your DeadGods that have claimed their y00t.",
              //   connectSubHeaderDeLay: "Coming in January 2024, or sooner.",
              //   stakePromptHeader: "Staked DeGods/DeadGods",
              //   stakePromptSubheader: "You dont have any staked",
              //   stakedTokensHeader: "Staked DeadGods w/ Claimed y00t",
              //   stakedTokensSubheader:
              //     "Staking DeadGods with claimed y00ts earns you 1 DePoint per minutes Staked.",
              //   showConnectInNav: true,
              // },
              // {
              //   key: "pointsParlor",
              //   label: "Points Parlor",
              //   description: "Win prizes for staking DeGods",
              //   path: "https://parlor.de.xyz/",
              //   enabled: true,
              //   externalLink: true,
              // },
              // {
              //   key: "downgrade",
              //   path: "/downgrade",
              //   label: "Downgrade",
              //   description: "Unlock the Season 3 version of your DeGod",
              //   enabled: true,
              // },
              // {
              //   key: "migration",
              //   label: "Migration",
              //   description: "Migrate your DeGods to Ethereum",
              //   path: "https://legacy.degods.com/migration",
              //   enabled: true,
              //   externalLink: true,
              // },
              // {
              //   key: "roadmap",
              //   label: "Roadmap",
              //   description: "Learn what's coming up for DeGods",
              //   path: "https://roadmap.degods.com",
              //   enabled: true,
              //   externalLink: true,
              // },
              // {
              //   key: "degodsDocumentation",
              //   label: "DeGods Documentation",
              //   description: "All you need to know about DeGods",
              //   path: "https://docs.degods.com",
              //   enabled: true,
              //   externalLink: true,
              // },
              {
                key: "buyDeGodsEth",
                label: "Buy DeGods on ETH",
                description: "Purchase DeGods on Blur",
                path: "https://blur.io/eth/collection/degods-eth",
                image: <></>,
                enabled: true,
                externalLink: true,
              },
            ],
          },
          {
            header: "",
            // header: "Spend $DUST",
            column: [
              // {
              //   key: "transcendDegods",
              //   label: "Transcend Your DeGods",
              //   description: "Spend $DUST to convert to a DeadGod",
              //   path: "https://deadgods.com",
              //   enabled: true,
              //   externalLink: true,
              // },
              // {
              //   key: "legacySite",
              //   label: "Legacy Website",
              //   description: "Solana version of the DeGods website",
              //   path: "https://legacy.degods.com",
              //   enabled: true,
              //   externalLink: true,
              // },
              // {
              //   key: "dustiesStore",
              //   label: "Dusties Store",
              //   description: "Spend $DUST on raffles and auctions",
              //   path: "https://store.degods.com",
              //   enabled: true,
              //   externalLink: true,
              // },
              // {
              //   key: "dustDocumentation",
              //   label: "$DUST Documentation",
              //   description: "All you need to know about the $DUST Protocol",
              //   path: "https://docs.dustprotocol.com/",
              //   enabled: true,
              //   externalLink: true,
              // },
              {
                key: "buyDeGodsSol",
                label: "Buy DeGods on SOL",
                description: "Purchase DeGods on Magic Eden",
                path: "https://magiceden.io/marketplace/degods",
                image: <></>,
                enabled: true,
                externalLink: true,
              },
            ],
          },
        ],
      },
    ],
  },
];

export const GLOBAL_CONFIG = process.env.NEXT_PUBLIC_PROJECT_ID
  ? allConfigs.filter(
      (conf) => conf.ID === process.env.NEXT_PUBLIC_PROJECT_ID
    )[0]
  : allConfigs.filter((conf) => conf.ID === DEFAULT_PROJECT_ID)[0];

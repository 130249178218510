import axios from "axios";
import { PublicClient, WalletClient, maxUint256 } from "viem";
import {
  degodsContract,
  degodsStakingContract,
  dustContract,
} from "@shared/constants";

const STAKING_CONTRACT_ADDRESS = process.env
  .NEXT_PUBLIC_STAKING_CONTRACT as `0x${string}`;
const NFT_CONTRACT_ADDRESS = process.env
  .NEXT_PUBLIC_NFT_CONTRACT as `0x${string}`;
const DUST_CONTRACT_ADDRESS = process.env
  .NEXT_PUBLIC_DUST_CONTRACT as `0x${string}`;

// Check if the staking contract is approved to transfer DUST
// * @param tokenIds - Array of token IDs to stake/unstake
// * @param publicClient - Viem public client
// * @param evmAddress - Wallet address on ETH or Polygon
// * @param action - Requested use case for DUST (stake or unstake)
export const isDUSTApproved = async (
  tokenIds: number[],
  publicClient: PublicClient,
  evmAddress: string,
  action: "stake" | "unstake"
) => {
  try {
    const fee = (await publicClient.readContract({
      address: STAKING_CONTRACT_ADDRESS,
      abi: degodsStakingContract,
      functionName: action === "stake" ? "stakeFee" : "unstakeFee",
    })) as bigint;

    const isRequired = fee > 0;

    const isApproved =
      ((await publicClient.readContract({
        address: DUST_CONTRACT_ADDRESS,
        abi: dustContract,
        functionName: "allowance",
        args: [evmAddress, STAKING_CONTRACT_ADDRESS],
      })) as bigint) >=
      fee * BigInt(tokenIds.length);

    return { isApproved, isRequired };
  } catch (e) {
    console.error("Fetch DUST balance error:", e);
    throw e;
  }
};

// Check if the staking contract is approved to transfer NFTs
// * @param publicClient - Viem public client
// * @param evmAddress - Wallet address on ETH or Polygon
export const isNftTransferApproved = async (
  publicClient: PublicClient,
  evmAddress: string
): Promise<boolean> => {
  const isApproved = (await publicClient.readContract({
    address: NFT_CONTRACT_ADDRESS,
    abi: degodsContract,
    functionName: "isApprovedForAll",
    args: [evmAddress, STAKING_CONTRACT_ADDRESS],
  })) as boolean;

  return isApproved;
};

// Check if the staking contract is approved to lock NFTs
// * @param publicClient - Viem public client
// * @param evmAddress - Wallet address on ETH or Polygon
export const isNftLockApproved = async (
  publicClient: PublicClient,
  evmAddress: string
): Promise<boolean> => {
  const isApproved = (await publicClient.readContract({
    address: NFT_CONTRACT_ADDRESS,
    abi: degodsContract,
    functionName: "isLockApprovedForAll",
    args: [evmAddress, STAKING_CONTRACT_ADDRESS],
  })) as boolean;

  return isApproved;
};

// Approve DUST transfer
// * @param tokenIds - Array of token IDs to stake
// * @param walletClient - Viem wallet client
// * @param publicClient - Viem public client
// * @param action - Requested use case for DUST (stake or unstake)
export const approveDUST = async (
  tokenIds: number[],
  walletClient: WalletClient,
  publicClient: PublicClient,
  action: "stake" | "unstake"
) => {
  const [account] = await walletClient.getAddresses();

  // Check if the staking contract is approved to transfer the DUST
  let dustApproval = await isDUSTApproved(
    tokenIds,
    publicClient,
    account,
    action
  );

  if (dustApproval.isRequired && !dustApproval.isApproved) {
    // Approve the staking contract to transfer the DUST
    try {
      const tx = await walletClient.writeContract({
        address: DUST_CONTRACT_ADDRESS,
        abi: dustContract,
        functionName: "approve",
        args: [STAKING_CONTRACT_ADDRESS, maxUint256],
        account,
        chain: walletClient.chain,
      });

      const receipt = await publicClient.waitForTransactionReceipt({
        hash: tx,
      });

      console.log("DUST approve receipt:", receipt);
    } catch (e) {
      console.error("DUST approve error:", e);
      throw e;
    }
  } else {
    console.log(
      `${
        dustApproval.isRequired
          ? "DUST already approved"
          : "DUST requirement waived"
      }`
    );
  }
};

// Approve NFT transfer
// * @param walletClient - Viem wallet client
// * @param publicClient - Viem public client
export const approveNftTransfer = async (
  walletClient: WalletClient,
  publicClient: PublicClient
) => {
  const [account] = await walletClient.getAddresses();

  // Check if the staking contract is approved to transfer the NFTs
  let isApproved = await isNftTransferApproved(publicClient, account);

  if (!isApproved) {
    // Approve the staking contract to transfer the NFTs
    try {
      const tx = await walletClient.writeContract({
        address: NFT_CONTRACT_ADDRESS,
        abi: degodsContract,
        functionName: "setApprovalForAll",
        args: [STAKING_CONTRACT_ADDRESS, true],
        account,
        chain: walletClient.chain,
      });

      const receipt = await publicClient.waitForTransactionReceipt({
        hash: tx,
      });

      console.log("Staking setApprovalForAll receipt:", receipt);
    } catch (e) {
      console.log("Staking setApprovalForAll error:", e);
      throw e;
    }
  } else {
    console.log("Staking already approved");
  }
};

// Approve NFT lock
// * @param walletClient - Viem wallet client
// * @param publicClient - Viem public client
export const approveNftLock = async (
  walletClient: WalletClient,
  publicClient: PublicClient
) => {
  const [account] = await walletClient.getAddresses();

  // Check if the staking contract is approved to transfer the NFTs
  let isApproved = await isNftLockApproved(publicClient, account);

  if (!isApproved) {
    // Approve the staking contract to transfer the NFTs
    try {
      const tx = await walletClient.writeContract({
        address: NFT_CONTRACT_ADDRESS,
        abi: degodsContract,
        functionName: "setLockApprovalForAll",
        args: [STAKING_CONTRACT_ADDRESS, true],
        account,
        chain: walletClient.chain,
      });

      const receipt = await publicClient.waitForTransactionReceipt({
        hash: tx,
      });

      console.log("Staking setLockApprovalForAll receipt:", receipt);
    } catch (e) {
      console.log("Staking setLockApprovalForAll error:", e);
      throw e;
    }
  } else {
    console.log("Staking already approved");
  }
};

// Stake NFTs
// * @param tokenIds - Array of token IDs to stake
// * @param walletClient - Viem wallet client
// * @param publicClient - Viem public client
export const stake = async (
  tokenIds: number[],
  walletClient: WalletClient,
  publicClient: PublicClient
) => {
  const [account] = await walletClient.getAddresses();

  // approve the staking contract to transfer the NFTs and DUST
  await approveNftLock(walletClient, publicClient);
  await approveDUST(tokenIds, walletClient, publicClient, "stake");

  // await txn success or fail
  try {
    const tx = await walletClient.writeContract({
      address: STAKING_CONTRACT_ADDRESS,
      abi: degodsStakingContract,
      functionName: "stake",
      args: [tokenIds],
      account,
      chain: walletClient.chain,
    });

    const receipt = await publicClient.waitForTransactionReceipt({
      hash: tx,
    });

    console.log("Stake receipt:", receipt);

    return receipt;
  } catch (e) {
    console.log("Staking error:", e);
    throw e;
  }
};

// UnStake NFTs
// * @param tokenIds - Array of token IDs to stake
// * @param walletClient - Viem wallet client
// * @param publicClient - Viem public client
export const unstake = async (
  tokenIds: number[],
  walletClient: WalletClient,
  publicClient: PublicClient
) => {
  const [account] = await walletClient.getAddresses();

  // Approve DUST transfer
  await approveDUST(tokenIds, walletClient, publicClient, "unstake");

  // unstake the NFTs
  try {
    const tx = await walletClient.writeContract({
      address: STAKING_CONTRACT_ADDRESS,
      abi: degodsStakingContract,
      functionName: "withdraw",
      args: [tokenIds],
      account,
      chain: walletClient.chain,
    });

    const receipt = await publicClient.waitForTransactionReceipt({
      hash: tx,
    });

    console.log("Withdraw receipt:", receipt);

    return receipt;
  } catch (e) {
    console.log("Withdraw error:", e);
    throw e;
  }
};

// fetch the owner address of the NFT
// * @param tokenId - Token ID of NFT
// * @param publicClient - Viem public client
export const getNftOwner = async (
  tokenId: number,
  publicClient: PublicClient
) => {
  const ownerAddress = (await publicClient.readContract({
    address: NFT_CONTRACT_ADDRESS,
    abi: degodsContract,
    functionName: "ownerOf",
    args: [tokenId],
  })) as string;

  return ownerAddress;
};

// fetch the tokenIds of the user's wallet NFTs
// * @param evmAddress - Wallet address on ETH or Polygon
// * @param publicClient - Viem public client
export const getWalletNFTs = async (evmAddress: string) => {
  try {
    let pageKey = "";
    const ownedNfts = [];

    while (pageKey === "" || !!pageKey) {
      let url = `${
        process.env.NEXT_PUBLIC_ALCHEMY_RPC ?? process.env.NEXT_PUBLIC_EVM_RPC
      }/getNFTs/?owner=${evmAddress}&contractAddresses[]=${
        process.env.NEXT_PUBLIC_NFT_CONTRACT
      }`;

      if (pageKey && pageKey !== "") url = url + "&pageKey=" + pageKey;

      const config = {
        method: "get",
        url: url,
      };

      const { data: data } = await axios(config);
      ownedNfts.push(
        ...data.ownedNfts.map((nft: any) => parseInt(nft.id.tokenId, 16))
      );
      pageKey = data.pageKey;
    }
    return ownedNfts;
  } catch (e) {
    console.log("Error fetching NFTs:", e);
    return [];
  }
};

// fetch the tokenIds of the user's staked NFTs
// * @param evmAddress - Wallet address on ETH or Polygon
// * @param provider - Provider of the transaction
export const getStakedNFTs = async (
  evmAddress: string,
  publicClient: PublicClient
) => {
  try {
    const stakedTokenIDs = (await publicClient.readContract({
      address: STAKING_CONTRACT_ADDRESS,
      abi: degodsStakingContract,
      functionName: "allStakedTokens",
      args: [evmAddress],
    })) as bigint[];

    let returnedStakedTokenIDs: number[] = stakedTokenIDs.map((id: bigint) =>
      Number(id)
    );
    return returnedStakedTokenIDs;
  } catch (e) {
    console.log("Error fetching staked NFTs:", e);
    return [];
  }
};

// returns staking and unstaking fee.
// * @param publicClient - Viem public client
export const getStakingFee = async (publicClient: PublicClient) => {
  const stakingFee =
    Number(
      (await publicClient.readContract({
        address: STAKING_CONTRACT_ADDRESS,
        abi: degodsStakingContract,
        functionName: "stakeFee",
      })) as bigint
    ) /
    10 ** 9;

  const unstakingFee =
    Number(
      (await publicClient.readContract({
        address: STAKING_CONTRACT_ADDRESS,
        abi: degodsStakingContract,
        functionName: "unstakeFee",
      })) as bigint
    ) /
    10 ** 9;

  return { stakingFee, unstakingFee };
};

// fetch the tokenIds of the user's staked NFTs
// * @param evmAddress - Wallet address on ETH or Polygon
// * @param publicClient - Viem public client
export const getTotalStaked = async (publicClient: PublicClient) => {
  try {
    const totalStaked = (await publicClient.readContract({
      address: STAKING_CONTRACT_ADDRESS,
      abi: degodsStakingContract,
      functionName: "totalStaked",
    })) as bigint;
    return Number(totalStaked);
  } catch (e) {
    console.log("Error fetching total staked NFTs:", e);
    return 0;
  }
};
